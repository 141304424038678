import { TableGrid } from '../../../packages/ui/organisms/table';
import { FILTER_COLLAPSABLE_SECTION_TYPE } from '../../../packages/ui/organisms/table/filter';

export const memberColumns = [
  { id: 'member', label: 'Member', sortable: true },
  { id: 'lastAppointment', label: 'Last Appointment', sortable: false },
  { id: 'nextAppointment', label: 'Next Appointment', sortable: false },
  { id: 'email', label: 'Email', sortable: true },
  { id: 'phone', label: 'Phone', sortable: false },
  { id: 'dob', label: 'Date of birth', sortable: false },
  { id: 'completed', label: 'Session', sortable: false },
  { id: 'state', label: 'State', sortable: false },
  { id: 'engagement', label: 'Engagement', sortable: false },
  { id: 'status', label: 'Status', sortable: true },
  // { id: 'SubStatus', label: 'SubStatus', sortable: false },
  // { id: 'isActiveChat', label: 'Chat status', sortable: false },
  { id: 'actions', label: '', sortable: false },
];

export const filterOptionsMock = [
  { label: 'Active in chat', value: 'active_chat' },
  { label: 'Inactive in chat', value: 'inactive_chat' },
  { label: 'At Least 2 Sessions', value: '2_sessions' },
  { label: 'No Sessions', value: 'no_sessions' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  // { label: 'Discharged', value: 'discharged' },
  // { label: 'Discharge HLOC', value: 'Discharge HLOC' },
  // {
  //  label: 'Discharged Asked to be Removed',
  //  value: 'Discharged Asked to be Removed',
  // },
  // { label: 'Discharged - No Logins', value: 'Discharged - No Logins' },
  // { label: 'Discharged - Unreachable', value: 'Discharged - Unreachable' },
  // { label: 'DIY', value: 'DIY' },
  // { label: 'Counseling DIY', value: 'Counseling DIY' },
  // { label: 'Coaching DIY', value: 'Coaching DIY' },
  // { label: 'Clinical DIY', value: 'Clinical DIY' },
  // { label: 'PMPM', value: 'pmpm' },
  // { label: 'Phase 1', value: 'Phase 1' },
  // { label: 'Phase 2', value: 'Phase 2' },
  { label: 'New Member', value: 'New member' },
  { label: 'Referral', value: 'Referral' },
  { label: 'Active', value: 'Active' },
  { label: 'Successful Discharge', value: 'Successful Discharge' },
  { label: 'Self Discharge', value: 'Self Discharge' },
  { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
  { label: 'Lost to Care', value: 'Lost to Care' },
  { label: 'Not Admitted to Care', value: 'Not admitted to care' },
  { label: 'DIY', value: 'Diy' },
  { label: 'Ghost', value: 'Ghost' },
  { label: 'Engaged', value: 'Engaged' },
  { label: 'Not engaged', value: 'Not engaged' },
  { label: 'Outreach needed', value: 'Outreach needed' },
];

export const multiSelectFilterOptionsMock = [
  {
    sectionTitle: 'State',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'Texas', value: 'Texas' },
      { label: 'Florida', value: 'Florida' },
      { label: 'Connecticut', value: 'Connecticut' },
      { label: 'Virginia', value: 'Virginia' },
      { label: 'New Hampshire', value: 'New Hampshire' },
    ],
  },
  {
    sectionTitle: 'Sessions Attended',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.RADIO,
    sectionOptions: [
      { label: 'At Least 2 Sessions', value: '2 Sessions' },
      { label: 'No Sessions', value: 'No Sessions' },
    ],
  },
  {
    sectionTitle: 'VBC',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'VBC flag', value: 'VBC flag' }],
  },
  {
    sectionTitle: 'Level of Engagement',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [
      { label: 'New Member', value: 'New member' },
      { label: 'Referral', value: 'Referral' },
      { label: 'Active', value: 'Active' },
      { label: 'Successful Discharge', value: 'Successful Discharge' },
      { label: 'Self Discharge', value: 'Self Discharge' },
      { label: 'Discharged to HLOC', value: 'Discharged to HLOC' },
      { label: 'Lost to Care', value: 'Lost to Care' },
      { label: 'Not Admitted to Care', value: 'Not admitted to care' },
      { label: 'DIY', value: 'Diy' },
      { label: 'Ghost', value: 'Ghost' },
      { label: 'Engaged', value: 'Engaged' },
      { label: 'Not engaged', value: 'Not engaged' },
      { label: 'Invite Pending', value: 'Invite pending' },
      { label: 'Invited', value: 'Invited' },
    ],
  },
  {
    sectionTitle: 'Outreach',
    sectionType: FILTER_COLLAPSABLE_SECTION_TYPE.DEFAULT,
    sectionOptions: [{ label: 'Outreach needed', value: 'Outreach needed' }],
  },
];
